// assets/scss/app.scss

// bootstrap
// @import '~bootswatch/flatly/variables';
// @import '~bootstrap/scss/bootstrap';
// @import '~bootswatch/flatly/bootswatch';

// font-awesome
@import '~font-awesome/scss/font-awesome';

// highlightjs: github theme
// @import '~highlight.js/styles/github.css';

@import './colors';

@mixin base-font {
  color: $color-default;
  font: {
    family: 'Open Sans', sans-serif;
    size: 14px;
  }

  line-height: 1.42857;
}

@mixin sort-arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  float: left;
  height: 0;
  width: 0;
}

// Basic styles
// -------------------------------------------------------------------------
a {
  color: #18BC9C;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #18BC9C;
    text-decoration: underline;
  }

  &:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  &#google-logout-link {
    display: block;
    padding-bottom: 20px;
    position: relative;
    top: 0;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: inherit;
  font-family: Lato,Helvetica Neue,Helvetica,Arial,sans-serif;
  font-weight: 400;
  line-height: 1.1;
}

h2 {
  padding: 10px;
}

p {
  margin-bottom: 0.5em;

  // debugging
  &#db-stats {
    display: none;
  }
}

input {
  &[type='checkbox'] {
    &.large {
      height: 22px;
      width: 22px;

      &::before {
        height: 20px;
        width: 20px;
      }

      &::after {
        height: 16px;
        top: -18px;
        width: 16px;
      }
    }

    &::before {
      background: $color-default-reverse;
      border: 1px solid #808080;
      content: '';
      display: block;
      height: 11px;
      position: relative;
      width: 11px;
    }

    &::after {
      background-image: linear-gradient(135deg, #b1b6be 0%, $color-default-reverse 100%);
      background-position: center;
      background-repeat: no-repeat;
      border-color: #b3b3b3 #dcddde #dcddde #b3b3b3;
      border-style: solid;
      border-width: 1px;
      content: '';
      display: block;
      height: 7px;
      left: 2px;
      position: relative;
      top: -11px;
      width: 7px;
    }

    &:checked {
      &::after {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAQAAABuW59YAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAB2SURBVHjaAGkAlv8A3QDyAP0A/QD+Dam3W+kCAAD8APYAAgTVZaZCGwwA5wr0AvcA+Dh+7UX/x24AqK3Wg/8nt6w4/5q71wAAVP9g/7rTXf9n/+9N+AAAtpJa/zf/S//DhP8H/wAA4gzWj2P4lsf0JP0A/wADAHB0Ngka6UmKAAAAAElFTkSuQmCC'), linear-gradient(135deg, #b1b6be 0%, $color-default-reverse 100%);
      }
    }

    &:disabled {
      &::after {
        filter: opacity(0.4);
      }

      //&::before {

      //}
    }

    &:not(:disabled) {
      &:checked {
        &:hover {
          &::after {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAQAAABuW59YAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAB2SURBVHjaAGkAlv8A3QDyAP0A/QD+Dam3W+kCAAD8APYAAgTVZaZCGwwA5wr0AvcA+Dh+7UX/x24AqK3Wg/8nt6w4/5q71wAAVP9g/7rTXf9n/+9N+AAAtpJa/zf/S//DhP8H/wAA4gzWj2P4lsf0JP0A/wADAHB0Ngka6UmKAAAAAElFTkSuQmCC'), linear-gradient(135deg, #8bb0c2 0%, $color-default-reverse 100%);
          }
        }
      }

      &:hover {
        &::after {
          background-image: linear-gradient(135deg, #8bb0c2 0%, $color-default-reverse 100%);
          border-color: #85a9bb #92c2da #92c2da #85a9bb;
        }

        &::before {
          border-color: #3d7591;
        }
      }
    }
  }
}

button {
  border: 1px solid $color-default-reverse;
  border-radius: 4px;
  color: $color-default-reverse;
  cursor: default;
  margin: 0.1em;
  min-width: 2em;
  opacity: 0.7;
  outline: none;
  padding: 3px 7px;
  text-transform: uppercase;
  transition: all 0.3s;

  &.enabled {
    &:not(.no-hover) {
      &:hover {
        border: 1px solid #000;
        box-shadow: 0 0 4px rgba(3, 3, 3, 0.8);
        color: $color-default-reverse;
        cursor: pointer;
        opacity: 1;
        text-shadow: 1px 1px 4px #000;
      }
    }
  }

  &.add,
  &.add-toggle {
    background: $background-good;

    &.enabled {
      background: $background-good;
      color: $color-default-reverse;
      opacity: 1;
    }
  }

  &.edit {
    background: $background-warning;

    &.editing { background: $background-warning-focus; }
  }

  &.cancel { background: #111; }

  &.delete { background: $background-danger; }

  &.save {
    background: $background-good;
    opacity: 0;
    padding: 3px 4px;

    &.enabled {
      background: $background-good;
      color: $color-default-reverse;
      opacity: 1;
    }
    &.saving { background: $background-good-focus; }
  }

  &.disabled {
    background: #ccc;
    border-color: $color-default-reverse;
    color: #ddd;
    cursor: default;
    text-shadow: none;
  }

  &#refreshRows {
    background: $background-good;
    border: 2px solid $color-default-reverse;
    color: $color-default-reverse;
    height: 2em;
    left: 0;
    opacity: 0.85;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    top: 13px;

    &:hover {
      background: lighten($background-good, 5%);
      border: 2px solid #000;
      box-shadow: 0 0 4px rgba(3, 3, 3, 0.8);
      cursor: pointer;
      opacity: 1;
      text-shadow: 1px 1px 4px #000;
    }
  }

  &#searchButton {
    background: $background-good;
    border: 1px solid $color-default-reverse;
    color: $color-default-reverse;
    height: 1.9em;
    opacity: 1;
    padding-top: 2px;
    position: relative;
    top: 0;

    &:hover {
      background: lighten($background-good, 5%);
      border: 2px solid #000;
      box-shadow: 0 0 4px rgba(3, 3, 3, 0.8);
      cursor: pointer;
      opacity: 1;
      text-shadow: 1px 1px 4px #000;
    }
  }
}

p,
ul,
ol {
  font-size: 19px;
  margin-bottom: 1.5em;
}

li {
  margin-bottom: 0.5em;
}

code {
  background: #ecf0f1;
  color: #2c3e50;
}

.text-danger {
  color: #e74c3c;

  &:hover {
    color: #e74c3c;
  }
}

i {
  margin-right: 0.25em;
}

.table {
  &.table-middle-aligned {
    th,
    td {
      vertical-align: middle;
    }
  }
}

// Utilities
// -------------------------------------------------------------------------
.m-b-0 { margin-bottom: 0; }

// Page elements
// -------------------------------------------------------------------------
html,
body {
  color: $color-default;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  height: 100%;
  line-height: 1.42857;
  margin: 0;
  text-align: left;
}

body {
  background: $background-default;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &#homepage {
    text-align: center;

    .body-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &#requirements_checker {
    header {
      h1 {
        margin-bottom: 0;
        margin-top: 0;

        span {
          font-size: 120%;
          opacity: 0.7;
          padding: 0 5px;
        }
      }
    }

    .panel {
      li {
        margin-bottom: 1em;
      }
    }
  }

  &#login {
    #login-users-help {
      p {
        font-size: 15px;
        line-height: 1.42857;

        &:last-child {
          margin-bottom: 0;
        }

        .label {
          margin-right: 5px;
        }

        .console {
          display: block;
          margin: 5px 0;
          padding: 10px;
        }
      }
    }
  }
}

header {
  margin-bottom: 0.5em;

  #user-block {
    font-size: 14px;

    .fa-user-circle {
      font-size: 24px;
      margin-right: 8px;
    }

    .fa-angle-down {
      font-size: 20px;
      font-weight: normal;
      height: 27px;
      line-height: 20px;
      margin-left: 5px;
      position: relative;
      top: 4px;
      width: 20px;
    }
  }

  ul {
    &.nav {
      li {
        margin-bottom: 0;
      }
    }
  }

  .navbar {
    background: linear-gradient(139.84deg, #1796b3 0%, #000000 100%);
    background-color: #1796b3;
    box-shadow: inset 0 -1px 0 0 #f4f4f4, 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    padding: 0;

    &::before {
      content: none;
    }

    & > .nav-links {
      display: block;
      font-size: 17px;
      margin-left: 0;
      margin-right: 0;
    }

    &.navbar-static-top {
      height: 64px;
    }

    .navbar-brand {
      margin: 0 15px;
      padding: 12px 0 16px 16px;
    }
  }

  .navbar-header {
    &::after,
    &::before {
      content: none;
    }
  }

  .navbar-nav {
    display: inline-block;
    font-size: 17px;

    .dropdown {
      width: 180px;

      .dropdown-toggle {
        &::after {
          content: none;
        }
      }

      .dropdown-menu {
        padding: 0;
        position: absolute;
        top: 60px;
        width: 100%;

        li {
          padding: 5px;

          a {
            clear: both;
            color: #7b8a8b;
            display: block;
            font-weight: 400;
            line-height: 1.42857;
            padding: 3px 20px;
            white-space: nowrap;

            &:focus,
            &:hover {
              background-color: #2c3e50;
              color: #fff;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .logo-hide-text {
    display: inline-block;
    overflow: hidden;
    position: relative;
    top: 5px;
    width: 28px;

    img {
      height: 26px;
    }
  }

  .search {
    color: $color-default-reverse;
    float: left;
    left: 12vw;
    margin: 0 auto;
    padding: 5px;
    position: relative;
    text-align: center;
    top: 8px;
    width: 230px;

    input {
      color: $color-default;
      padding-left: 5px;
      padding-right: 0;
      width: 175px;

      &::placeholder {
        color: $color-content-placeholder;
      }
    }
  }

  .subnavbar {
    background: $background-subnavbar;
    color: $color-default-reverse;
    height: 40px;
    padding: 5px;
    width: 100%;

    input {
      color: $color-default;
      padding-left: 5px;
      padding-right: 5px;

      &::placeholder {
        color: $color-content-placeholder;
      }
    }
  }

  .locales {
    a {
      padding-right: 10px;
    }
  }
}

.body-container {
  flex: 1;
  // needed to prevent pages with a very small height and browsers not supporting flex
  min-height: 600px;

  #main {
    h1,
    h2 {
      margin-top: 0;
    }
  }
}

.container-fluid {
  &:after,
  &:before {
    content: " ";
    display: table;
  }
}

.navbar-default {
  .navbar-nav {
    & > li {
      & > a {
        color: #fff;

        &:focus,
        &:hover {
          background-color: transparent;
          color: #18bc9c;
        }
      }
    }
  }

  .navbar-brand,
  .navbar-text {
    color: #fff;
  }
}

.navbar-brand {
  float: left;
  font-size: 19px;
  height: 60px;
  line-height: 1;
  padding: 19.5px 15px;
}

.nav {
  & > li {
    display: block;
    position: relative;

    & > a {
      display: block;
      position: relative;

      &:focus,
      &:hover {
        background-color: #ecf0f1;
        text-decoration: none;
      }
    }
  }
}

.navbar-collapse,
.nav {
  &:after,
  &:before {
    content: " ";
    display: table;
  }
}

#sidebar {
  &.section {
    margin-bottom: 2em;
  }

  p {
    font-size: 15px;

    & + p {
      margin: 1.5em 0 0;
    }
  }
}

footer {
  background: #ecf0f1;
  margin-top: 2em;
  padding-bottom: 2em;
  padding-top: 2em;

  p {
    color: #7b8a8b;
    font-size: 13px;
    margin-bottom: 0.25em;
  }

  #footer-resources {
    text-align: right;

    i {
      color: #7b8a8b;
      font-size: 28.5px;
      margin-left: 0.5em;
    }
  }
}

// Forms
// -------------------------------------------------------------------------
.form-group {
  &.has-error {
    .form-control {
      border-color: #e74c3c;
    }

    .control-label {
      color: #e74c3c;
    }

    .help-block {
      background-color: #e74c3c;
      color: #fff;
      font-size: 15px;
      padding: 1em;

      ul,
      li {
        margin-bottom: 0;
      }

      li + li {
        margin-top: 0.5em;
      }
    }
  }
}

textarea {
  max-width: 100%;
}

// Misc
// -------------------------------------------------------------------------
.text-primary {
  color: #1796b3;
}

.page-header {
  margin: 5px 0;
  padding-bottom: 0;
}

#sourceCodeModal {
  h3 {
    font-size: 19px;
    margin-top: 0;

    small {
      color: #7b8a8b;
      font-size: 80%;
    }
  }

  pre {
    margin-bottom: 2em;
    padding: 0;
  }
}

#confirmationModal {
  .modal-dialog {
    width: 500px;
  }

  .modal-footer {
    button {
      min-width: 75px;
    }
  }
}

.alert {
  background-color: rgba(231, 76, 60, 0.8);
  left: 30%;
  margin: 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: 40%;
  z-index: 1100;
}

// custom alerts from using cgfunding
#alert {
  border: 0;
  color: $color-default-reverse;
  display: none;
  height: auto;
  left: 0;
  margin: 0 auto;
  opacity: 0.9;
  padding: 3px 10px;
  position: absolute;
  text-align: center;
  top: 0;
  transition: all 0.3s;
  width: 100%;
  word-break: break-all;
  z-index: 3000;

  &:hover {
    cursor: pointer;
  }

  &.alert-create {
    background-color: $background-good;
    &:hover { background-color: lighten($background-good, 15%); }
  }

  &.alert-update {
    background-color: $background-warning;
    &:hover { background-color: lighten($background-warning, 15%); }
  }

  &.alert-info {
    background-color: $background-info;
    &:hover { background-color: lighten($background-info, 15%); }
  }

  &.alert-delete {
    background-color: $background-danger;
    &:hover { background-color: lighten($background-danger, 15%); }
  }

  &.alert-error {
    background-color: $background-danger;
    &:hover { background-color: lighten($background-danger, 15%); }
  }

  #close {
    background: #ccc;
    border: 1px solid #000;
    font-weight: bold;
    height: 24px;
    padding: 0 3px;
    position: absolute;
    right: 5px;
    top: 5px;
    transition: all 0.3s;

    a { color: #000; }

    &:hover {
      background: #000;
      cursor: pointer;

      a {
        color: $color-default-reverse;
        text-decoration: none;
      }
    }
  }
}

// pagination
.pagination-controls {
  margin: 0 auto;
  min-height: 25px;
  text-align: center;

  .pagination {
    margin: 0;
    padding: 0;

    ul {
      margin-bottom: 0;

      li {
        display: block;
        float: left;
        font-size: 14px;
        list-style-type: none;
        margin-bottom: 0;
        margin-right: 10px;
        width: 20px;

        &.active {
          a {
            color: #ccc;
          }
        }
      }
    }
  }
}

span {
  &.editable-enabled {
    background: $color-content-editable;
    border: 1px solid darken($color-content-non-editable, 20%);
    box-shadow: 1px 1px 1px 0 darken($color-content-editable, 20%) inset;
    color: #000;
    cursor: text;
    min-height: 24px;
    padding-bottom: 3px;

    a {
      cursor: text;
    }
  }

  &.invalid {
    background-color: $background-danger;
    border: 1px solid $background-danger-focus;
    color: $color-default-reverse;
  }
}

#topLevelAdd {
  float: none;
  height: 40px;
  text-align: center;

  fieldset {
    display: inline-block;

    button {
      background: #6aa112;
      color: #f3f3f3;
      cursor: pointer;
      float: left;

      &:hover {
        border: 1px solid #000;
        box-shadow: 0 0 4px rgba(3, 3, 3, 0.8);
        color: $color-default-reverse;
        cursor: pointer;
        opacity: 1;
        text-shadow: 1px 1px 4px #000;
      }
    }

    label {
      float: left;
      padding: 0 5px;
      position: relative;
      top: 5px;
    }

    input {
      float: left;
      height: 30px;
      padding: 4px;
      width: 10vw;
    }
  }
}

// wrapper for all tables
#data-container {
  padding: 0 0 50px;
  position: relative;
  text-align: center;
  width: 100%;
}

table {
  background: $background-default;
  color: $color-default;
  text-align: left;
  width: 100%;

  caption {
    caption-side: top;
    font-size: 20px;
  }

  tr {
    &.even {
      background-color: #d2deea;
    }
  }

  td {
    padding: 4px 10px;
  }

  // main codes table
  &.codes {
    margin-top: 50px;
    opacity: 1;
    position: relative;
    transition: all 0.3s;
    width: 100%;

    &.indent {
      margin-top: 19px;
    }

    tbody > tr > td,
    tbody > tr > th,
    tfoot > tr > td,
    tfoot > tr > th,
    thead > tr > td,
    thead > tr > th {
      padding: 4px 8px;
      vertical-align: inherit;
    }

    th {
      &.active {
        a {
          color: $th-a-active;
          display: block;
          float: left;
        }

        div {
          left: 4px;
          position: relative;
          top: 8px;

          &.active-asc {
            @include sort-arrow;

            border-bottom: 5px solid #000;
          }

          &.active-desc {
            @include sort-arrow;

            border-top: 5px solid #000;
          }
        }
      }

      a {
        color: $th-a-inactive;
        text-decoration: none;

        &:hover {
          color: $th-a-active;
          text-decoration: underline;
        }
      }

      &.code {
        width: 100px;
      }

      &.id,
      &.pred,
      &.code,
      &.depth,
      &.expired {
        text-align: center;
      }

      &.empty {
        width: 10px;
      }
    }

    tr {
      border-bottom: 1px solid #ddd;
    }

    thead {
      display: none;
    }

    tbody {
      tr {
        background-color: $background-default;

        // add rows are hidden until displayed via js
        &.add {
          display: none;

          td {
            transition: all 1s;

            span {
              min-height: 1.8em;
              text-transform: uppercase;
            }

            &.title {
              span {
                &.text {
                  min-width: 100px;
                }
              }
            }
          }
        }

        // expired rows get strikethrough
        &.expired {
          td {
            background: $background-default;
            color: $color-content-expired;
            text-decoration: line-through;

            span {
              background: $background-default;
              border: 0;
              box-shadow: none;
              color: $color-content-expired;
            }
          }
        }

        // value rows have pred, code, title, expired
        &.values {
          // stripe colors
          &.even {
            background-color: darken($background-default, 5%);
          }

          td {
            span {
              display: block;
            }
          }
        }

        // no rows returned from db
        &#rowEmpty {
          td {
            text-align: center;
          }
        }
      }

      td {
        display: table-cell;
        height: 30px;
        min-width: 5vw;

        &.id,
        &.pred,
        &.code,
        &.depth,
        &.expired {
          text-align: center;
        }

        &.add-button {
          padding: 8px 4px;
          text-align: right;
          vertical-align: middle;
        }

        &.edit-buttons {
          position: relative;
          text-align: right;

          button {
            float: left;
          }
        }

        &.id {
          font-weight: 700;
          min-width: 2vw;
          text-align: right;
        }

        &.pred {
          color: darken(#18bc9c, 15%);
          min-width: 75px;
        }
      }

      &.index-indent {
        top: 0;

        td {
          .code {
            text-align: left;
          }

          a {
            span {
              cursor: pointer;
            }
          }

          span {
            cursor: default;
            display: block;
            font-size: 1em;
            max-height: 3.6em;
            max-width: 52vw;
            min-width: 5vw;
            padding: 3px 5px 0 0;
            transition: all 0.3s;

            &.dash {
              color: lighten($color-default, 70%);
              float: left;
              max-height: none;
              max-width: none;
              min-width: auto;
              padding: 0 1px 0 0;
            }

            &.editable {
              &.text {
                float: left;
              }
            }
          }
        }

        // column values editable
        tr {
          &.values {
            td {
              &.editable-enabled {
                border: 1px solid darken($color-content-non-editable, 30%);
              }
            }
          }
        }
      }
    }

    &.obscure {
      opacity: 0.3;
    }
  }

  // admin users table
  &.users {
    th {
      background-color: #2c3e50;
      color: #fff;
      padding: 2px 10px;
    }

    tr {
      &.even {
        background-color: #d2deea;
      }
    }

    td {
      background-color: none;
      padding: 4px 10px;
    }
  }

  // admin user key table
  &.key {
    border: 1px solid #222;
    position: relative;
    top: -20px;

    td {
      border: 1px solid #222;
      padding: 4px 10px;
    }
  }

  .role-admin {
    background-color: #d4ead2;
    color: #387533;
  }

  .role-super-admin {
    background-color: #ded2ea;
    color: #583375;
  }
}

// loading modal during refreshRows()
#loading {
  background-color: $background-good;
  border: 2px solid #000;
  border-radius: 5px;
  color: $color-default-reverse;
  font-size: 1.5em;
  height: 2em;
  left: 28vw;
  margin: 0 auto;
  opacity: 0;
  padding: 5px;
  position: fixed;
  text-align: center;
  text-shadow: 1px 1px 4px #000;
  top: 45vh;
  width: 45vw;
  z-index: 0;

  &.show {
    opacity: 0.85;
    z-index: 1002;
  }
}

.svg-inline--fa {
  vertical-align: -0.125em;

  &.fa-search {
    vertical-align: -0.15em;
  }

  &.fa-user-circle {
    vertical-align: -0.3em;
  }

  &.fa-angle-down {
    vertical-align: -0.2em;
  }
}

.fixed-top {
  position: fixed;
}

footer {
  background-color: $background-navbar;
  bottom: 0;
  color: $color-default-reverse;
  left: 0;
  padding: 1em 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 1030;
}

// zendesk widget
iframe {
  &.zEWidget-launcher { // sass-lint:disable-line class-name-format
    bottom: 0;
  }

  &#launcher {
    bottom: 25px !important;
  }
}

@media (max-width: 480px) {
  header {
    .search {
      input {
        width: 100%;
      }
    }
  }

  .navbar-brand {
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .codes {
    tbody > tr > td,
    tbody > tr > th,
    tfoot > tr > td,
    tfoot > tr > th,
    thead > tr > td,
    thead > tr > th {
      padding: 4px 2px;
    }
  }
}

@media (max-width: 570px) {
  header {
    .search {
      left: 2vw;
      top: 11px;
      width: 40%;
    }
  }

  button {
    &#searchButton {
      display: none;
    }
  }
}

@media (min-width: 768px) {
  .navbar-header {
    float: left;
  }

  .navbar-collapse {
    &.collapse {
      display: block !important;
      height: auto !important;
      overflow: visible !important;
      padding-bottom: 0;
    }
  }

  .navbar-toggle {
    display: none;
  }

  .navbar-fixed-bottom .navbar-collapse,
  .navbar-fixed-top .navbar-collapse,
  .navbar-static-top .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-right {
    float: right !important;
    margin-right: -15px;

    .dropdown-menu {
      left: auto;
      right: 0;
    }
  }

  .navbar-nav {
    float: left;
    margin: 0;

    & > li {
      & > a {
        padding-top: 14.5px;
      }
    }
  }

  .navbar > .container-fluid .navbar-brand,
  .navbar > .container .navbar-brand {
    margin-left: -15px;
  }

  .container-fluid > .navbar-collapse,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container > .navbar-header {
    margin-right: 0;
    margin-left: 0;
  }
}

@media screen and (max-width: 800) {
  tr {
    border: 1px solid #000;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0.5em 0;
  }

  td,
  th {
    border: 0.5px solid $tr-border-color;
    flex: 1 1 150;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .container {
    width: 98%;
  }
}
