// assets/scss/colors.scss

$theme-colors: (
  'primary': #0074d9,
  'danger': #ff4136
);

// background
$background-default: darken(#fff, 5%);
$background-good: #6aa112;
$background-good-focus: #46a712;
$background-info: #a75b13;
$background-info-focus: #a78412;
$background-warning: #8ea713;
$background-warning-focus: #a0a712;
$background-danger: #cf3266;
$background-danger-focus: #a71232;
$background-content-expired: #fff;
$background-subnavbar: lighten(#000, 10%);
$background-navbar: #000;

// text
$color-default: lighten(#000, 5%);
$color-default-reverse: darken(#f6f6f6, 1%);
$color-content-editable: #fcfcfc;
$color-content-non-editable: #777;
$color-content-placeholder: #aaa;
$color-content-expired: #ccc;

// table
$th-a-active: #000;
$th-a-inactive: #000;
$tr-border-color: rgba(3, 3, 3, 0.2);

// footer
$background-color-footer: #c2dee6;
